import React, { useCallback, useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Filter from "../../Components/Filter/Filter";
import CarCard from "../../Components/CarCard/CarCard";
import "./HomeScreen.css";
import { getAllCars } from "../../services/apis";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import Loading from "../../Components/Loading/Loading";
import FilterComponent from "../../Components/FilterComponent/FilterComponent";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../config/url";
import useMobileView from "../../hooks";
import MobileFilterComponent from "../../Components/MobileFilterComponent/MobileFilterComponent";
import NotFound from "../../Components/NotFound/NotFound";

function HomeScreen() {
  const [loading, setLoading] = useState(false);
  const [cars, setCars] = useState([]);
  const [error, setError] = useState(null);

  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const isMobile = useMobileView();

  const [brands, setBrands] = useState([]);
  const [places, setPlaces] = useState([]);
  const [years, setYears] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState("latest");

  const itemsPerPage = 6;

  const [filters, setFilters] = useState({
    car_name: null,
    brand: null,
    model: null,
    year: null,
    place: null,
    kilometer: null,
    owner: null,
    shop_name: null,
    dealer: null,
    loan_available: null,
    varient: null,
    claim: null,
    sold: null,
    page: 1,
    limit: itemsPerPage,
    search: "",
    sort: "createdAt",
  });

  useEffect(() => {
    const fetchCarsBrand = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/admin/get-cars`);
        if (res && res.data) {
          const newCars = res?.data?.data;
          if (newCars) {
            setBrands([...new Set(newCars.map((car) => car.brand))]);
            setPlaces([...new Set(newCars.map((car) => car.place))]);
            setYears([...new Set(newCars.map((car) => car.model))]);
          }
        }
      } catch (error) {
        console.error("Error fetching cars:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCarsBrand();
  }, []);

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      try {
        const res = await axios.get(`${BACKEND_URL}/api/v1/admin/get-cars`, {
          params: filters,
        });
        if (res && res.data) {
          const newCars = res?.data?.data;
          if (newCars) {
            const sortedCars = newCars.sort((a, b) => {
              return sortOrder === "latest"
                ? new Date(b.createdAt) - new Date(a.createdAt)
                : new Date(a.createdAt) - new Date(b.createdAt);
            });
            setCars(sortedCars);
            const totalItems = res.data.totalCars;
            setTotalPages(Math.ceil(totalItems / itemsPerPage));
            setHasMore(newCars.length > 0 && cars.length < totalItems);
          }
        }
      } catch (error) {
        console.error("Error fetching cars:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCars();
  }, [filters, sortOrder]);

  const handleFilterChange = (updatedFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...updatedFilters,
      page: 1,
    }));
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = () => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      search: searchQuery,
      page: 1,
    }));
  };

  const handleSortChange = (e) => {
    const selectedOrder = e.target.value;
    setSortOrder(selectedOrder);
    setFilters((prevFilters) => ({
      ...prevFilters,
      sort: selectedOrder === "latest" ? "desc" : "asc",
      page: 1,
    }));
  };

  const loadMoreCars = () => {
    if (hasMore) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        page: prevFilters.page + 1,
      }));
    }
  };

  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 &&
      !loading &&
      hasMore
    ) {
      loadMoreCars();
    }
  }, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div>
      <div className="container-fluid p-4">
        <div className="row mt-1">
          {loading ? (
            <Loading />
          ) : error ? (
            <ErrorMessage message={error} />
          ) : (
            <>
              <div className="col-md-3">
                {isMobile ? (
                  <MobileFilterComponent
                    brands={brands}
                    years={years}
                    places={places}
                    onFilterChange={handleFilterChange}
                  />
                ) : (
                  <FilterComponent
                    brands={brands}
                    years={years}
                    places={places}
                    onFilterChange={handleFilterChange}
                  />
                )}
              </div>
              <div className="col-md-9">
                <div className="search-sort-bar">
                  <select
                    className="sort-dropdown"
                    value={sortOrder}
                    onChange={handleSortChange}
                  >
                    <option value="latest">Latest</option>
                    <option value="oldest">Oldest</option>
                  </select>
                </div>
                <div className="car-list">
                  {cars.length === 0 && !loading ? (
                    <div
                      className="w-100"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <NotFound />
                    </div>
                  ) : (
                    cars.map((car) => <CarCard key={car._id} car={car} />)
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomeScreen;
