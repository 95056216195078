import React, { useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { BACKEND_URL } from "../../config/url";
import "./SellCarScreen.css";

function SellCarScreen() {
  const [loading, setLoading] = useState(false);
  const [carDetails, setCarDetails] = useState({
    name: "",
    phone: "",
    location: "",
    car_name: "",
    description: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCarDetails({ ...carDetails, [name]: value });

    if (value.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    if (!carDetails.name.trim()) formErrors.name = "Name is required";
    if (!carDetails.phone.trim()) formErrors.phone = "Phone number is required";
    if (!carDetails.location.trim())
      formErrors.location = "Location is required";
    if (!carDetails.car_name.trim())
      formErrors.car_name = "Car name is required";
    return formErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    Swal.fire({
      title: "Please check the details, Do you want to continue?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Send",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await axios.post(
          `${BACKEND_URL}/api/v1/customer/sell-my-car`,
          carDetails
        );
        setLoading(false);
        setCarDetails({
          name: "",
          phone: "",
          location: "",
          car_name: "",
          description: "",
        });
        setShowModal(true);
      }
    });
  };

  return (
    <div className="sell-car-screen">
      <div className="process-header">
        <h1>How Selling Works with WheelzLoop</h1>
        <p>We make selling your car fast, easy, and hassle-free.</p>
      </div>

      <div className="process-steps">
        <div className="step">
          <h2>Step 1: Enter Your Car Details</h2>
          <p>
            Provide basic information about your car such as the model, year,
            price, and description. This helps us understand your car better.
          </p>
        </div>
        <div className="step">
          <h2>Step 2: We Connect with You</h2>
          <p>
            Once you submit your car details, our team will review the
            information and contact you for more details or to set up an
            inspection if needed.
          </p>
        </div>
        <div className="step">
          <h2>Step 3: Find Potential Buyers</h2>
          <p>
            We list your car on our marketplace, reaching thousands of potential
            buyers. You can sit back and let us handle the process.
          </p>
        </div>
        <div className="step">
          <h2>Step 4: Finalize the Deal</h2>
          <p>
            When we find a buyer, we will assist in finalizing the sale,
            ensuring a smooth and secure transaction.
          </p>
        </div>
      </div>

      <div className="sell-car-header">
        <h1>Ready to Sell Your Car?</h1>
        <p>Fill out the form below, and we'll get started on the process!</p>
      </div>

      <form className="sell-car-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={carDetails.name}
            onChange={handleInputChange}
          />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="model">Contact Number</label>
          <input
            type="text"
            id="model"
            name="phone"
            placeholder="Enter Phone number"
            value={carDetails.phone}
            onChange={handleInputChange}
          />
          {errors.phone && (
            <span className="error-message">{errors.phone}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="year">Location</label>
          <input
            type="text"
            id="year"
            name="location"
            placeholder="Enter your Location"
            value={carDetails.location}
            onChange={handleInputChange}
          />
          {errors.location && (
            <span className="error-message">{errors.location}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="price">Car name</label>
          <input
            type="text"
            id="price"
            name="car_name"
            placeholder="Alto, swift ...."
            value={carDetails.car_name}
            onChange={handleInputChange}
          />
          {errors.car_name && (
            <span className="error-message">{errors.car_name}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="description">Car Description</label>
          <textarea
            id="description"
            name="description"
            placeholder="Enter car description"
            value={carDetails.description}
            onChange={handleInputChange}
          ></textarea>
        </div>
        <button type="submit" className="submit-btn" disabled={loading}>
          {loading ? <>Please Wait....</> : <>Submit Car Details</>}
        </button>
      </form>

      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>We Received Your Inquiry</h2>
            <p>We will verify your details and will connect with you soon.</p>
            <button
              className="btn btn-success"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SellCarScreen;
