import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Tab,
  Tabs,
} from "@mui/material";
import "./CarCategoriesSlider.css";
import { useNavigate } from "react-router-dom";
import { getAllCars } from "../../services/apis";
import Loading from "../../Components/Loading/Loading";
import { useCars } from "../../context/CarContext";

const categories = ["All", "SUV", "Sedan", "Hatchback"];

const CarCategoriesSlider = () => {
  const [currentCategory, setCurrentCategory] = useState("All");
  const { cars, loading, error } = useCars();

  const filteredCars =
    currentCategory === "All"
      ? cars
      : cars.filter(
          (car) => car.category.toLowerCase() === currentCategory.toLowerCase()
        );

  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setCurrentCategory(newValue);
  };

  const handleViewAll = () => {
    navigate("/cars");
  };

  return (
    <Container className="car-categories-slider">
      <Typography variant="h4" align="center" gutterBottom>
        Car Categories
      </Typography>
      <div>
        <button onClick={handleViewAll} className="view-all-button">
          View All
        </button>
        <Tabs value={currentCategory} onChange={handleTabChange}>
          {categories.map((category) => (
            <Tab key={category} label={category} value={category} />
          ))}
        </Tabs>

        <Box className="car-list"> 
          {loading && <Loading />}
          {error && (
            <Typography variant="h6" align="center" color="error">
              {error}
            </Typography>
          )}

          {filteredCars &&
            filteredCars.length > 0 &&
            filteredCars?.map((car) => (
              <Card
                key={car._id}
                className="car-card"
                onClick={() => {
                  navigate(`/details/${car._id}`);
                }}
              >
                <CardMedia
                  component="img"
                  alt={car.car_name}
                  image={car.image}
                  className="car-image"
                />
                <CardContent>
                  <Typography variant="h6" className="car-name">
                    {car.car_name}
                  </Typography>
                  <Typography variant="body1" className="car-price">
                    ₹ {car.price}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </Box>
      </div>
    </Container>
  );
};

export default CarCategoriesSlider;
