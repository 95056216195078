import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen/HomeScreen";
import DetailsScreen from "./Screens/DetailsScreen/DetailsScreen";
import Footer from "./Components/Footer/Footer";
import LandingScreen from "./Screens/LandingScreen/LandingScreen";
import Header from "./Components/Header/Header";
import Modal from "react-modal";
import FavouriteScreen from "./Screens/FavouriteScreen/FavouriteScreen";
import AboutUs from "./Screens/AboutUs/AboutUs";
import SellCarScreen from "./Screens/SellCarScreen/SellCarScreen";
import { CarProvider } from "./context/CarContext";
import BackToTopButton from "./Components/BackToTopButton/BackToTopButton";
import CookieConsent from "react-cookie-consent";
import ReviewScreen from "./Screens/ReviewScreen/ReviewScreen";

function App() {
  useEffect(() => {
    Modal.setAppElement("#root");
  }, []);
  return (
    <CarProvider>
      <CookieConsent
        location="bottom"
        buttonText="Agree"
        declineButtonText="Decline"
        cookieName="myAppCookieConsent"
        style={{ background: "#242424", color: "#FFF" }}
        buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
        declineButtonStyle={{ fontSize: "14px" }}
        expires={150}
      >
        We use cookies to personalize content and ads, to provide social media
        features, and to analyze our traffic.
      </CookieConsent>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<LandingScreen />} />
          <Route path="/cars" element={<HomeScreen />} />
          <Route path="/details/:id" element={<DetailsScreen />} />
          <Route path="/favourites" element={<FavouriteScreen />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/sell-car" element={<SellCarScreen />} />
          <Route path="/reviews" element={<ReviewScreen />} />
        </Routes>
        <BackToTopButton />
        <Footer />
      </BrowserRouter>
    </CarProvider>
  );
}

export default App;
