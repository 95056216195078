import React from "react";
import "./Testimonials.css";
import { Avatar } from "@mui/material";

function Testimonials() {
  return (
    <div className="testimonials-container">
      <h1 className="title">What Our Customers Say</h1>
      <div className="testimonials-carousel">
        <div className="testimonial-card">
          <Avatar
            src="/images/customer1.jpg"
            alt="Customer 1"
            className="avatar"
          />
          <p className="testimonial-text">
            "Selling my car was so easy and quick! The team was very
            professional, and the whole process was smooth from start to
            finish."
          </p>
          <h3 className="customer-name">Ashwin</h3>
          <span className="customer-details">
            Car Sold: Toyota Corolla 2018
          </span>
        </div>

        <div className="testimonial-card">
          <Avatar
            src="/images/customer2.jpg"
            alt="Customer 2"
            className="avatar"
          />
          <p className="testimonial-text">
            "I couldn’t be happier with the service! They helped me find a buyer
            in just a few days, and I felt supported the entire way."
          </p>
          <h3 className="customer-name">Vishnu</h3>
          <span className="customer-details">Car Sold: Honda Civic 2020</span>
        </div>

        <div className="testimonial-card">
          <Avatar
            src="/images/customer3.jpg"
            alt="Customer 3"
            className="avatar"
          />
          <p className="testimonial-text">
            "Amazing experience! They made sure everything was handled
            professionally and securely. Highly recommend!"
          </p>
          <h3 className="customer-name">Akash</h3>
          <span className="customer-details">Car Sold: Ford Focus 2019</span>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
