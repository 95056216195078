import React from "react";

function Loading() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div class="spinner-border text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
}

export default Loading;
