import React from "react";
import "./HowITWorks.css";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"; 

function HowITWorks() {
  return (
    <div className="how-it-works-container">
      <h1 className="title">How It Works</h1>
      <p className="intro-text">
        Selling your car is easy. Follow these simple steps to get started.
      </p>
      <div className="steps-container">
        <div className="step">
          <div className="icon-container">
            <DirectionsCarIcon className="icon" />
          </div>
          <h2 className="step-title">Step 1: List Your Car</h2>
          <p className="step-description">
            Click the <strong>Sell a Car</strong> button in the header, fill out
            the form with basic details like make, model, condition, and price.
          </p>
        </div>

        <div className="step">
          <div className="icon-container">
            <AssignmentTurnedInIcon className="icon" />
          </div>
          <h2 className="step-title">Step 2: We Verify Your Information</h2>
          <p className="step-description">
            Our team reviews your submission and verifies the details. You’ll
            hear from us within 2 days.
          </p>
        </div>

        <div className="step">
          <div className="icon-container">
            <SupportAgentIcon className="icon" />
          </div>
          <h2 className="step-title">Step 3: Assistance in Listing</h2>
          <p className="step-description">
            Once verified, we help you list your car on our website, making it
            available to potential buyers.
          </p>
        </div>

        <div className="step">
          <div className="icon-container">
            <AttachMoneyIcon className="icon" />
          </div>
          <h2 className="step-title">Step 4: Finalize the Sale</h2>
          <p className="step-description">
            When we find a buyer, we assist in closing the deal, ensuring a
            smooth and secure transaction.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HowITWorks;
