import React, { createContext, useState, useEffect, useContext } from "react";
import { getAllCars } from "../services/apis";

const CarContext = createContext();

export const useCars = () => {
  return useContext(CarContext);
};

export const CarProvider = ({ children }) => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCars = async () => {
      setLoading(true);
      try {
        const response = await getAllCars();
        setCars(response);
      } catch (err) {
        setError(err.message || "Failed to fetch cars");
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, []);

  return (
    <CarContext.Provider value={{ cars, loading, error }}>
      {children}
    </CarContext.Provider>
  );
};
