import React from "react";
import "./AboutUs.css";

function AboutUs() {
  return (
    <div>
      <div className="about-screen">
        {/* <div className="about-banner">
          <h1 className="about-title">About WheelzLoop</h1>
          <p className="about-subtitle">
            Connecting you to your dream ride, effortlessly.
          </p>
        </div> */}
        <div className="about-container">
          <div className="about-image">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRL5nTG8F0WolIeEGlPVB8d-v_TzeRLiL4v-w&s"
              alt="Used Cars"
            />
          </div>
          <div className="about-text">
            <div className="about-section">
              <h2 className="section-title">Who We Are</h2>
              <p className="section-text">
                Welcome to <strong>WheelzLoop</strong>, your go-to platform for
                buying and selling used cars. We strive to provide a seamless
                and reliable marketplace where you can easily find the perfect
                car for your needs.
              </p>
            </div>
            <div className="about-section">
              <h2 className="section-title">What We Offer</h2>
              <p className="section-text">
                We offer a vast selection of certified used cars, a
                user-friendly platform, and secure communication between buyers
                and sellers. At WheelzLoop, transparency and trust are at the
                core of everything we do.
              </p>
            </div>
            <div className="about-section">
              <h2 className="section-title">Why Choose Us?</h2>
              <ul className="benefits-list">
                <li>Vast selection of quality cars</li>
                <li>Secure and easy transactions</li>
                <li>Trusted marketplace with great deals</li>
                <li>Personalized support for every customer</li>
              </ul>
            </div>
            <div className="about-section">
              <h2 className="section-title">Our Mission</h2>
              <p className="section-text">
                Our mission is to create the ultimate car buying and selling
                experience by connecting people with the best-used cars and a
                trusted community of buyers and sellers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
