import React from "react";
import "./Banner.css";
import useMobileView from "../../hooks";
import { BANNER_IMAGE } from "../../constants/imageUrls";

function Banner() {
  const isMobile = useMobileView();
  return (
    <div>
      {isMobile ? (
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-6 mt-4">
              <div className="justify-center">
                <h4 className="banner-text">
                  Find Your Perfect Ride –{" "}
                  <span style={{ color: "#111" }}>Quality Used Cars</span> at
                  Unbeatable Prices!
                </h4>
              </div>
            </div>
            <div className="col-md-6">
              <img src={BANNER_IMAGE} className="w-100" />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="banner3.png"
                  class="d-block w-100"
                  alt="..."
                  style={{ height: "550px" }}
                />
              </div>
              <div class="carousel-item">
                <img
                  src="banner2.png"
                  class="d-block w-100"
                  alt="..."
                  style={{ height: "550px" }}
                />
              </div>
              <div class="carousel-item">
                <img
                  src="banner1.png"
                  class="d-block w-100"
                  alt="..."
                  style={{ height: "550px" }}
                />
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              id="arr-icons"
              data-target="#carouselExampleIndicators"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              id="arr-icons"
              data-target="#carouselExampleIndicators"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Banner;
