import React, { useState } from "react";
import "./Filter.css";

function Filter() {
  const [budget, setBudget] = useState([100000, 5000000]);
  const [isMobileOpen, setMobileOpen] = useState(false);

  return (
    <>
      <button className="filter-toggle-btn" onClick={() => setMobileOpen(true)}>
        Filter
      </button>

      <div className={`filter-container ${isMobileOpen ? "open" : ""}`}>
        <div className="filter-header">
          <h4>Filter Cars</h4>
          <button className="close-btn" onClick={() => setMobileOpen(false)}>
            &times;
          </button>
        </div>

        <div className="filter-content">
          <div className="filter-section">
            <h5>Categories</h5>
            <div className="checkbox-group">
              <label>
                <input type="checkbox" /> Sedan
              </label>
              <label>
                <input type="checkbox" /> SUV
              </label>
              <label>
                <input type="checkbox" /> Hatchback
              </label>
            </div>
          </div>

          <div className="filter-section">
            <h5>Budget (₹)</h5>
            <input
              type="range"
              min="100000"
              max="10000000"
              value={budget[0]}
              onChange={(e) => setBudget([e.target.value, budget[1]])}
            />
            <span>
              {budget[0]} - {budget[1]}
            </span>
          </div>

          <div className="filter-section">
            <h5>Location</h5>
            <input type="text" placeholder="Enter location" />
          </div>

          <div className="filter-section">
            <h5>Year</h5>
            <input type="number" placeholder="Enter year" />
          </div>

          <div className="filter-section">
            <h5>Kilometers Driven</h5>
            <input type="number" placeholder="Enter kilometers" />
          </div>

          <div className="filter-section">
            <h5>Fuel Type</h5>
            <div className="checkbox-group">
              <label>
                <input type="checkbox" /> Petrol
              </label>
              <label>
                <input type="checkbox" /> Diesel
              </label>
              <label>
                <input type="checkbox" /> Electric
              </label>
            </div>
          </div>

          <div className="filter-section">
            <h5>Transmission</h5>
            <div className="checkbox-group">
              <label>
                <input type="checkbox" /> Manual
              </label>
              <label>
                <input type="checkbox" /> Automatic
              </label>
            </div>
          </div>

          <div className="filter-section">
            <h5>Brand</h5>
            <input type="text" placeholder="Enter brand" />
          </div>
        </div>

        <button
          className="filter-apply-btn"
          onClick={() => setMobileOpen(false)}
        >
          Apply Filters
        </button>
      </div>
    </>
  );
}

export default Filter;
