import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import "./NewSales.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useCars } from "../../context/CarContext";
import Loading from "../../Components/Loading/Loading";
import ErrorMessage from "../../Components/ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";

const NewSales = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 4;
  const navigate = useNavigate();
  const { cars, loading, error } = useCars();

  const handleNext = () => {
    if (currentIndex + itemsPerPage < cars.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  const handlePrev = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  return (
    <Container className="new-sales-container">
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        className="new-sales-heading"
      >
        New Sales
      </Typography>
      {loading ? (
        <Loading />
      ) : error ? (
        <ErrorMessage error={error} />
      ) : (
        <>
          <Box className="sales-slider">
            <ChevronLeftIcon
              onClick={handlePrev}
              disabled={currentIndex === 0}
              className="slider-button"
            >
              Prev
            </ChevronLeftIcon>
            <Box className="sales-list">
              {cars &&
                cars.length > 0 &&
                cars
                  .slice(currentIndex, currentIndex + itemsPerPage)
                  .map((car, index) => (
                    <Card
                      key={index}
                      className="car-card"
                      onClick={() => {
                        navigate(`/details/${car._id}`);
                      }}
                    >
                      <div className="new-flag">New</div>
                      <CardMedia
                        component="img"
                        alt={car.car_name}
                        image={car.image}
                        className="car-image"
                      />
                      <CardContent>
                        <Typography variant="h6" className="car-name">
                          {car.car_name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {car.model}
                        </Typography>
                        <Typography variant="body1" className="car-price">
                          ₹ {car.price}
                        </Typography>
                        {/* <Typography variant="body1" className="car-location">
                          ₹ {car?.location}
                        </Typography> */}
                      </CardContent>
                    </Card>
                  ))}
            </Box>
            <ChevronRightIcon
              onClick={handleNext}
              disabled={currentIndex + itemsPerPage >= cars.length}
              className="slider-button"
            >
              Next
            </ChevronRightIcon>
          </Box>
        </>
      )}
    </Container>
  );
};

export default NewSales;
