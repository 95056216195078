import React from "react";
import "./LandingScreen.css";
import Header from "../../Components/Header/Header";
import Carousel from "../../Components/Carousel/Carousel";
import About from "../../Sections/About/About";
import WhyChooseUs from "../../Sections/WhyChooseUs/WhyChooseUs";
import Banner from "../../Components/Banner/Banner";
import FeaturesSection from "../../Sections/FeaturesSection/FeaturesSection";
import PopularBrands from "../../Sections/PopularBrands/PopularBrands";
import NewSales from "../../Sections/NewSales/NewSales";
import CarCategoriesSlider from "../../Sections/CarCategoriesSlider/CarCategoriesSlider";
import HowITWorks from "../../Sections/HowITWorks/HowITWorks";
import Testimonials from "../../Sections/Testimonials/Testimonials";
import { useCars } from "../../context/CarContext";
import NotFound from "../../Components/NotFound/NotFound";

function LandingScreen() {
  const { cars, loading, error } = useCars();
  return (
    <div>
      {/* <Header /> */}
      <Banner />
      <FeaturesSection />
      {/* <PopularBrands /> */}
      {!loading && cars?.length == 0 ? (
        <>
          <NotFound />
        </>
      ) : error ? (
        <>Error</>
      ) : (
        <>
          <NewSales />
          <CarCategoriesSlider />
        </>
      )}
      <HowITWorks />
      <Testimonials />
      <PopularBrands />
    </div>
  );
}

export default LandingScreen;
