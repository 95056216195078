import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./BackToTopButton.css";

const BackToTopButton = () => {
  const [visible, setVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className={`back-to-top ${visible ? "visible" : ""}`}> 
      <IconButton
        color="primary"
        aria-label="back to top"
        onClick={scrollToTop}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          fontSize: "40px",
          height:'50px',
          width:'50px',
          backgroundColor:'#1a9d74',
          color:"#fff"
        }}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
    </div>
  );
};

export default BackToTopButton;
